@import 'src/utils/utils';

.offer-result {
  margin-top: 15px;
  margin-bottom: 15px;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  &__wrapper {
    @include Text-16-reg;
    width: 100%;
    max-width: 800px;
    padding-top: 20px;
    padding-right: 16px;
    padding-bottom: 20px;
    padding-left: 16px;
    background-color: $Main-2;

    @media(min-width: $md) {
      padding-top: 35px;
      padding-right: 40px;
      padding-bottom: 35px;
      padding-left: 40px;
      border-radius: 20px;
    }

    table {
      width: 100%;
    }

    tr {
      display: flex;
      flex-direction: column;
      gap: 5px;
  
      @media(min-width: $md) {
        display: table-row;
        margin-top: unset;
      }
  
      &:not(:first-child) {
        margin-top: 10px;
  
        td {
          @media(min-width: $md) {
            padding-top: 10px;
          }
        }
      }
    }

    td {
      @include Text-16-reg;
      vertical-align: top;

      &:first-child {
        @include Text-16-bold;
        vertical-align: top;
        white-space: nowrap;
    
        @media(min-width: $md) {
          padding-right: 40px;
        }
      }
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0;
      border-top: 1px solid $Main-5;

      @media(min-width: $md) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    p {
      margin-top: 15px;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      display: inline-block;
      padding-left: 22px;
      text-decoration: none;
      color: $Corporate-2;

      @media(min-width: $lg) {
        padding-left: 28px;
      }

      @media(min-width: $xl) {
        @include Text-16-reg;
      }

      &:hover {
        @media(min-width: $lg) {
          text-decoration: underline;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/link.svg');
        background-size: contain;
        background-position: center;
        content: '';

        @media(min-width: $lg) {
          top: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__title {
    @include Head-25-bold;
    margin: 0;
    margin-bottom: 15px;
  }
}